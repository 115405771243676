import axios from 'axios';
import { server } from '../../server/server';

// Create Product Unit
export const createProductUnit = (newProductUnit) => async (dispatch) => {
  try {
    dispatch({ type: 'productUnitCreateRequest' });

    const { data } = await axios.post(`${server}/product-unit/create-product-unit`, newProductUnit);

    dispatch({
      type: 'productUnitCreateSuccess',
      payload: data.productUnit,
    });
  } catch (error) {
    dispatch({
      type: 'productUnitCreateFail',
      payload: error.response.data.message,
    });
  }
};

// Fetch All Product Units
export const getAllProductUnits = () => async (dispatch) => {
  try {
    dispatch({ type: 'getAllProductUnitsRequest' });

    const { data } = await axios.get(`${server}/product-unit/get-all-product-units`);

    dispatch({
      type: 'getAllProductUnitsSuccess',
      payload: data.productUnits,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data
      ? error.response.data.message
      : error.message; // Fallback to a generic error message if no response

    dispatch({
      type: 'getAllProductUnitsFailed',
      payload: errorMessage,
    });
  }
};

// Edit Product Unit
export const editProductUnit = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: 'editProductUnitRequest' });

    const { data } = await axios.put(`${server}/product-unit/update-product-unit/${id}`, updatedData);

    dispatch({
      type: 'editProductUnitSuccess',
      payload: data.productUnit,
    });

    // Optionally, fetch all product units again to update the list
    dispatch(getAllProductUnits());
  } catch (error) {
    dispatch({
      type: 'editProductUnitFail',
      payload: error.response.data.message,
    });
  }
};

// Delete Product Unit
export const deleteProductUnit = (id) => async (dispatch) => {
  try {
    dispatch({ type: 'deleteProductUnitRequest' });

    const { data } = await axios.delete(`${server}/product-unit/delete-product-unit/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: 'deleteProductUnitSuccess',
      payload: data.message,
    });

    // Optionally, fetch all product units again to update the list
    dispatch(getAllProductUnits());
  } catch (error) {
    dispatch({
      type: 'deleteProductUnitFail',
      payload: error.response.data.message,
    });
  }
};
