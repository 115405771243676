/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createCategory, getAllCategories } from '../../../redux/actions/categoryAction.js';

const CreateCategoryModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.category);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      description,
    };

    dispatch(createCategory(categoryData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Category created successfully!');
      dispatch(getAllCategories()); // Fetch updated category list
      setName('');
      setDescription('');
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            // required
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Category'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCategoryModal;