/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { FiImage } from "react-icons/fi";
import { addTocart } from "../../../redux/actions/cartAction";

const ProductCard = ({ data }) => {

  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const stock = data && data.quantity;

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);

    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1, stock };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  }

  return (
    <div className="col-xl-3 col-md-6 col-sm-6 col-6">
      <div className="card product-item">
        {data?.image_Url && data?.image_Url.length > 0 ? (
          <img
            src={data?.image_Url[0].url}
            className=" img-fluid"
            alt={data?.name.length > 8 ? data?.name.slice(0, 8) + "..." : data?.name}
          />
        ) : (
          <div
            className="d-flex justify-content-center align-items-center card-img-top img-fluid "
            style={{ height: '80px' }}
          >
            <FiImage size={60} color="#ccc" />
          </div>
        )}
        <div className="card-body custom-card-body">
          <h5 className="card-subtitle text-muted mb-1">
            {data?.category?.name?.length > 15 ? data?.category?.name.slice(0, 15) + "..." : data?.category?.name}
          </h5>
          <h5 className="card-title mb-0 fs-6">
            {data?.name?.length > 20 ? data?.name?.slice(0, 20) + "..." : data?.name}
          </h5>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="card-text fw-bold">
              Ksh.{data?.sellingPrice}
            </p>
            <p className={stock === 0 ? "text-danger p-card-text" : "text-primary"}>
              {stock === 0 ? "Out of stock" : `${stock} pcs`}
            </p>
          </div>
          <div className="text-center my-2">
            <button
              className="btn btn-outline-primary"
              role="button"
              onClick={() => addToCartHandler(data?._id)}
            >
              Add To Cart
            </button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ProductCard