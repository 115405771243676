import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createOrder, getAllOrders } from '../../../redux/actions/orderAction';
import { getAllCustomers } from '../../../redux/actions/customerAction'; // Import action to fetch customers

const CreateOrderModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.order);
  const { customers } = useSelector((state) => state.customer); // Get customers from Redux state

  const [customerId, setCustomerId] = useState('');
  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    if (show) {
      dispatch(getAllCustomers()); // Fetch customers when the modal is shown
    }
  }, [show, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const orderData = {
      customer: customerId,
      totalAmount,
    };

    // console.log(orderData)


    dispatch(createOrder(orderData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Order created successfully!');
      dispatch(getAllOrders());
      setCustomerId('');
      setTotalAmount('');
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Customer</Form.Label>
                <Form.Control
                  as="select"
                  value={customerId}
                  onChange={(e) => setCustomerId(e.target.value)}
                  required
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer._id} value={customer._id}>
                      {customer.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Order'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateOrderModal;