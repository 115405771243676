import PropTypes from 'prop-types';

const BankPayBillForm = ({ paybillDetails, handlePaybillDetailsChange }) => (
  <div className="mt-3">
    <div className="mb-3">
      <label htmlFor="paybillDetails" className="form-label">Bank PayBill Transaction Reference</label>
      <input
        type="text"
        className="form-control"
        id="paybillDetails"
        value={paybillDetails}
        onChange={handlePaybillDetailsChange}
        placeholder="Enter Bank PayBill transaction reference"
      />
    </div>
  </div>
);

BankPayBillForm.propTypes = {
  paybillDetails: PropTypes.string.isRequired,
  handlePaybillDetailsChange: PropTypes.func.isRequired
};

export default BankPayBillForm;
