import { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { server } from '../../../server/server';

const SettingsPage = () => {
  const [settings, setSettings] = useState({
    siteName: '',
    siteURL: '',
    logoURL: '',
    contactEmail: '',
    contactPhone: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      instagram: '',
    },
    aboutUs: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${server}/settings`, { withCredentials: true });
        setSettings(response.data);
      } catch (error) {
        console.log(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('socialLinks')) {
      const socialMedia = name.split('.')[1];
      setSettings((prevSettings) => ({
        ...prevSettings,
        socialLinks: {
          ...prevSettings.socialLinks,
          [socialMedia]: value,
        },
      }));
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`${server}/settings/update`, settings, { withCredentials: true });
      setSuccess('Settings updated successfully');
      toast.success('Settings updated successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update settings');
      toast.error(error.response?.data?.message || 'Failed to update settings');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1>Site Settings</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="siteName">
                  <Form.Label>Site Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter site name"
                    name="siteName"
                    value={settings.siteName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="siteURL">
                  <Form.Label>Site URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter site URL"
                    name="siteURL"
                    value={settings.siteURL}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="logoURL">
                  <Form.Label>Logo URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter logo URL"
                    name="logoURL"
                    value={settings.logoURL}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="contactEmail">
                  <Form.Label>Contact Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter contact email"
                    name="contactEmail"
                    value={settings.contactEmail}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="contactPhone">
                  <Form.Label>Contact Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact phone"
                    name="contactPhone"
                    value={settings.contactPhone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="socialLinks.facebook">
                  <Form.Label>Facebook URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Facebook URL"
                    name="socialLinks.facebook"
                    value={settings.socialLinks.facebook}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="socialLinks.twitter">
                  <Form.Label>Twitter URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Twitter URL"
                    name="socialLinks.twitter"
                    value={settings.socialLinks.twitter}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="socialLinks.instagram">
                  <Form.Label>Instagram URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Instagram URL"
                    name="socialLinks.instagram"
                    value={settings.socialLinks.instagram}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="aboutUs">
                  <Form.Label>About Us</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter about us information"
                    name="aboutUs"
                    value={settings.aboutUs}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Update Settings'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsPage;
