import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';

import CreateSupplierModal from './CreateSupplierModal.jsx';
import EditSupplierModal from './EditSupplierModal.jsx';
import { deleteSupplier, getAllSuppliers } from '../../../redux/actions/supplierAction';
const SuppliersPage = () => {
  const dispatch = useDispatch();
  const { suppliers, error, message, isLoading } = useSelector((state) => state.supplier);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingSupplier, setEditingSupplier] = useState(null);
  const [supplierIdToDelete, setSupplierIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllSuppliers());
    if (error) {
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [error, message, dispatch]);

  const handleDelete = (supplierId) => {
    setSupplierIdToDelete(supplierId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSupplier(supplierIdToDelete));
    setShowConfirmDeleteModal(false);
    setSupplierIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setSupplierIdToDelete(null);
  };

  const handleEdit = (supplier) => {
    setEditingSupplier(supplier);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingSupplier(null);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col className="d-flex align-items-center">
          <h2>Suppliers</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Add Supplier
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='table-container mt-2' >
            <Table striped bordered hover className='table-responsive'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Address</th>
                  <th>Company</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {suppliers && suppliers.map((supplier,index) => (
                  <tr key={supplier._id}>
                    <td>{index+1}</td>
                    <td>{supplier.name}</td>
                    <td>{supplier.email}</td>
                    <td>{supplier.phoneNumber}</td>
                    <td>{supplier.address}</td>
                    <td>{supplier.company}</td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => handleEdit(supplier)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        className='ms-2'
                        onClick={() => handleDelete(supplier._id)}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showCreateModal && (
              <CreateSupplierModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showModal && editingSupplier && (
              <EditSupplierModal
                show={showModal}
                onHide={handleModalClose}
                supplier={editingSupplier}
              />
            )}
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SuppliersPage;
