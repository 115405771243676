import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Table } from 'react-bootstrap';
import CartSingle from './CartSingle.jsx';
import CheckoutModal from '../checkout/CheckoutModal.jsx';
import { addTocart, removeFromCart } from '../../../redux/actions/cartAction';

import "./Cart.css"
const Cart = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);

  useEffect(() => {
    // Initialize cart state from local storage
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      const cartItems = JSON.parse(savedCartItems);
      cartItems.forEach(item => {
        dispatch(addTocart(item));
      });
    }

  }, [dispatch]);


  const subtotal = cart.reduce((acc, item) => acc + item.qty * item.sellingPrice, 0);
  const tax = subtotal * 0.16; // Assuming a 16% tax rate
  const total = subtotal;


  const handleSubmitOrder = () => {
    if (!selectedCustomer) {
      toast.error("Please select a customer before proceeding.");
      return;
    }

    const orderData = {
      cart,
      total,
      customer: selectedCustomer,
      tax
    };

    localStorage.setItem("latestOrder", JSON.stringify(orderData));
    handleShow();
  };

  const handleSuspend = () => {
    console.log("Cart suspended.");
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="container-fluid border p-2">
      <div className="row" >
      <div className="col-12">
      <div style={{ minHeight: '430px',maxHeight:"600px",overflowY: 'auto' }}>
    <Table striped bordered hover size='sm'>
      <thead>
        <tr>
          <th>Item Name</th>
          <th className="text-center">Qty</th>
          <th className="text-right">Price</th>
          <th className="text-right">Subtotal</th>
          <th className="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {cart && cart.length > 0 ? (
          cart.map((item, index) => (
            <CartSingle
              key={index}
              data={item}
              quantityChangeHandler={quantityChangeHandler}
              removeFromCartHandler={removeFromCartHandler}
            />
          ))
        ) : (
          <tr className="hover:bg-transparent">
            <td colSpan="5" className="text-center" style={{ height: '30vh' }}>
              No items in cart
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
</div>

      </div>
      {cart.length > 0 && (
        <div className='container-fluid'>
          <div className="row justify-content-end mt-4 border-top">
            <div className="col-8">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between">
                  <span>Subtotal :</span>
                  <span>Ksh.{subtotal.toFixed(2)}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between" style={{ fontSize: "14px" }}>
                  <span className="fs-6">VAT :</span>
                  {/* <span>Ksh.{tax.toFixed(2)}</span> */}
                  <span>0.00</span>
                </li>
                <li className="list-group-item d-flex justify-content-between font-weight-bold">
                  <span>Total :</span>
                  <span>Ksh.{total.toFixed(2)}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-12 d-flex justify-content-center">
              <button className="btn btn-danger mx-2" onClick={handleSuspend} aria-label="Suspend the order">
                Suspend
              </button>
              <div>
                <Button variant="primary" onClick={handleSubmitOrder} aria-label="Proceed to payment">
                  Pay Now
                </Button>
                <CheckoutModal show={show} handleClose={handleClose} total={total} />
              </div>
            </div>
          </div>
        </div>

      )}
    </div>
  );
};

Cart.propTypes = {
  selectedCustomer: PropTypes.object,
};

export default memo(Cart);