import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import CreateProductUnitModal from './CreateProductUnitModal.jsx';
import EditProductUnitModal from './EditProductUnitModal.jsx';
import { deleteProductUnit, getAllProductUnits } from '../../../redux/actions/productUnitAction.js';

const ProductUnitPage = () => {
  const dispatch = useDispatch();
  const { productUnits, error, message, isLoading } = useSelector((state) => state.productUnit);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingProductUnit, setEditingProductUnit] = useState(null);
  const [productUnitIdToDelete, setProductUnitIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllProductUnits());
    if (error) {
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [error, message, dispatch]);

  const handleDelete = (productUnitId) => {
    setProductUnitIdToDelete(productUnitId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteProductUnit(productUnitIdToDelete));
    setShowConfirmDeleteModal(false);
    setProductUnitIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setProductUnitIdToDelete(null);
  };

  const handleEdit = (productUnit) => {
    setEditingProductUnit(productUnit);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingProductUnit(null);
  };

  return (
    <div className='container-fluid'>
      <Row className='mb-2'>
        <Col className="d-flex align-items-center">
          <h2 className='mb-0'>Product Units</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Add Unit
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Unit Name</th>
                  <th>Abbreviation</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productUnits && productUnits.map((productUnit, index) => (
                  <tr key={productUnit._id}>
                    <td>{index + 1}</td>
                    <td>{productUnit.unitName}</td>
                    <td>{productUnit.abbreviation}</td>
                    <td>{productUnit.description}</td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => handleEdit(productUnit)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        className='ms-2'
                        onClick={() => handleDelete(productUnit._id)}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {showCreateModal && (
            <CreateProductUnitModal
              show={showCreateModal}
              onHide={handleCreateModalClose}
            />
          )}
          {showModal && editingProductUnit && (
            <EditProductUnitModal
              show={showModal}
              onHide={handleModalClose}
              productUnit={editingProductUnit}
            />
          )}
          <ConfirmDeleteModal
            show={showConfirmDeleteModal}
            onClose={handleCloseConfirmDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProductUnitPage;
