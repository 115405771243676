/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createRole, getAllRoles } from '../../../redux/actions/roleAction.js';

const CreateRoleModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.role);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const roleData = {
      name,
      description,
    };

    dispatch(createRole(roleData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Role created successfully!');
      dispatch(getAllRoles()); // Fetch updated role list
      setName('');
      setDescription('');
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Role'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateRoleModal;