import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import PaginationComponent from '../../components/pagination/PaginationComponent';

import CreateStock from './CreateStock.jsx';
import EditStockModal from './EditStockModal.jsx';
import { deleteStock, getAllStocks } from '../../../redux/actions/stockAction';

const InventoryPage = () => {
  const dispatch = useDispatch();
  const { stocks, isLoading, error, message } = useSelector((state) => state.stock);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [editingStock, setEditingStock] = useState(null);
  const [stockIdToDelete, setStockIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllStocks());
    if (error) {
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [dispatch, error, message]);

  const handleDelete = (stockId) => {
    setStockIdToDelete(stockId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStock(stockIdToDelete));
    setShowConfirmDeleteModal(false);
    setStockIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setStockIdToDelete(null);
  };

  const handleEdit = (stock) => {
    setEditingStock(stock);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingStock(null);
  };


  const productsPerPage = 14;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(stocks.length / productsPerPage);

  // Calculate the products to display based on current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentStocks = stocks.slice(startIndex, startIndex + productsPerPage);


  return (
    <div className="container-fluid">
      <Row>
        <Col className="d-flex align-items-center">
          <h2 className="mb-0">Inventory</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Add Stock
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="table-container">
            <Table striped bordered hover className="mt-2">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Store</th>
                  <th>Quantity</th>
                  <th>Date Added</th>
                  <th>Last Updated</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {currentStocks && currentStocks.map((stock, index) => (
                  <tr key={stock._id}>
                    <td>{index + 1}</td>
                    <td>{stock.product?.name}</td>
                    <td>{stock.store?.name}</td>
                    <td>{stock.quantity}</td>
                    <td>{new Date(stock.createdAt).toLocaleString('en-KE')}</td>
                    <td>{new Date(stock.updatedAt).toLocaleString('en-KE')}</td>
                    <td>
                      <Button variant="info" size="sm" className="me-2" onClick={() => handleEdit(stock)}>
                        Edit
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleDelete(stock._id)} disabled={isLoading}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showCreateModal && (
              <CreateStock show={showCreateModal} onHide={() => setShowCreateModal(false)} />
            )}
            {showEditModal && editingStock && (
              <EditStockModal show={showEditModal} onHide={handleCloseEditModal} stock={editingStock} />
            )}
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
            {/* Only show pagination if the total number of products exceeds productsPerPage */}
            {stocks.length > productsPerPage && (
              <div className="d-flex justify-content-center mt-2">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InventoryPage;