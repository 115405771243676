import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const UserProfilePage = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Img variant="top" src={user?.profilePicture} alt="Profile Picture" />
            <Card.Body>
              <Card.Title>{user?.name}</Card.Title>
              <Card.Text>{user?.bio}</Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <strong>First Name:</strong> {user?.firstName}
                  </Col>
                  <Col>
                    <strong>Last Name:</strong> {user?.lastName}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <strong>Email:</strong> {user?.email}
                  </Col>
                </Row>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <Row>
                  <Col>
                    <strong>Branch:</strong> {user?.branch?.name}
                  </Col>
                </Row>
              </ListGroup.Item> */}
              <ListGroup.Item>
                <Row>
                  <Col>
                    <strong>Store:</strong> {user?.store.name}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <strong>Phone No:</strong> {user?.phoneNumber}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <strong>Role:</strong> {user?.role.name}
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UserProfilePage;
