import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  productVariant: null,
  productVariants: [],
  success: false,
  error: null,
  message: null,
};

export const productVariantReducer = createReducer(initialState, (builder) => {
  builder
    // Create Product Variant
    .addCase('createProductVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createProductVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.productVariant = action.payload;
      state.success = true;
      state.message = "Product Variant created successfully!";
    })
    .addCase('createProductVariantFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Product Variants
    .addCase('getAllProductVariantsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllProductVariantsSuccess', (state, action) => {
      state.isLoading = false;
      state.productVariants = action.payload;
    })
    .addCase('getAllProductVariantsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Product Variant
    .addCase('editProductVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editProductVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.productVariant = action.payload;
      state.success = true;
      state.message = "Product Variant updated successfully!";
    })
    .addCase('editProductVariantFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Product Variant
    .addCase('deleteProductVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteProductVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteProductVariantFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
