/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { editProductVariant } from '../../../redux/actions/productVariantAction.js';
import { AiOutlineClose } from 'react-icons/ai';

const EditProductVariantModal = ({ show, onHide, productVariant }) => {
  const dispatch = useDispatch();

  const [variantType, setVariantType] = useState('Size'); // Added state for variant type
  const [variationInput, setVariationInput] = useState('');
  const [variations, setVariations] = useState([]);

  useEffect(() => {
    if (productVariant) {
      setVariantType(productVariant.variantType || 'Size'); // Set initial variant type
      setVariations(productVariant.variations || []);
    }
  }, [productVariant]);

  const handleAddVariation = () => {
    if (variationInput && !variations.includes(variationInput)) {
      setVariations([...variations, variationInput]);
      setVariationInput('');
    }
  };

  const handleRemoveVariation = (variationToRemove) => {
    setVariations(variations.filter(variation => variation !== variationToRemove));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editProductVariant(productVariant._id, { variantType, variations }));
    onHide(); // Close modal after submission
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit Product Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Variant Type</Form.Label>
            <Form.Control
              as="select"
              value={variantType}
              onChange={(e) => setVariantType(e.target.value)}
              disabled
            >
              <option value="Size">Size</option>
              <option value="Color">Color</option>
              <option value="Weight">Weight</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Variations</Form.Label>
            <InputGroup className="mb-2">
              <FormControl
                type="text"
                value={variationInput}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setVariationInput(inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase());
                }}
                placeholder={`Enter ${variantType.toLowerCase()} (e.g., S, M, L, XL)`}
              />
              <Button variant="outline-primary" onClick={handleAddVariation}>
                Add
              </Button>
            </InputGroup>
            <div>
              {variations.map((variation, index) => (
                <span key={index} className="badge bg-primary d-inline-flex align-items-center me-2">
                  {variation}
                  <AiOutlineClose size={15} className="ms-2 cursor-pointer" onClick={() => handleRemoveVariation(variation)} />
                </span>
              ))}
            </div>
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductVariantModal;