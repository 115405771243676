import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const storeReducer = createReducer(initialState, (builder) => {
  builder
    // Create Store
    .addCase('storeCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('storeCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.store = action.payload;
      state.success = true;
      state.message = "Store created successfully!";
    })
    .addCase('storeCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Stores
    .addCase('getAllStoresRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllStoresSuccess', (state, action) => {
      state.isLoading = false;
      state.stores = action.payload;
    })
    .addCase('getAllStoresFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Store
    .addCase('editStoreRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editStoreSuccess', (state, action) => {
      state.isLoading = false;
      state.store = action.payload;
      state.success = true;
      state.message = "Store updated successfully!";
    })
    .addCase('editStoreFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Store
    .addCase('deleteStoreRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteStoreSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteStoreFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
