import axios from "axios";
import { server } from "../../server/server";

// Create Product Variant
export const createProductVariant = (newProductVariant) => async (dispatch) => {
  try {
    dispatch({ type: "createProductVariantRequest" });

    const { data } = await axios.post(`${server}/product-variant/create-product-variant`, newProductVariant);

    dispatch({
      type: "createProductVariantSuccess",
      payload: data.productVariant,
    });
  } catch (error) {
    dispatch({
      type: "createProductVariantFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Product Variants
export const getAllProductVariants = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllProductVariantsRequest" });

    const { data } = await axios.get(`${server}/product-variant/get-all-product-variants`);

    dispatch({
      type: "getAllProductVariantsSuccess",
      payload: data.productVariants,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductVariantsFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Product Variant
export const editProductVariant = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editProductVariantRequest" });

    const { data } = await axios.put(`${server}/product-variant/update-product-variant/${id}`, updatedData);

    dispatch({
      type: "editProductVariantSuccess",
      payload: data.productVariant,
    });

    // Optionally, fetch all product variants again to update the list
    dispatch(getAllProductVariants());
  } catch (error) {
    dispatch({
      type: "editProductVariantFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Product Variant
export const deleteProductVariant = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteProductVariantRequest" });

    const { data } = await axios.delete(`${server}/product-variant/delete-product-variant/${id}`);

    dispatch({
      type: "deleteProductVariantSuccess",
      payload: data.message,
    });

    // Optionally, fetch all product variants again to update the list
    dispatch(getAllProductVariants());
  } catch (error) {
    dispatch({
      type: "deleteProductVariantFailed",
      payload: error.response.data.message,
    });
  }
};
