/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../../redux/actions/customerAction';

const CustomerSearch = ({ setSelectedCustomer }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const { customers } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);

  useEffect(() => {
    // Set "Walk-In-Customer" as default if available
    const defaultCustomer = customers && customers.find(customer => customer.name === "Walk-In-Customer");
    if (defaultCustomer) {
      setSelectedCustomer(defaultCustomer);
      setSearchTerm(defaultCustomer.name);
    }
  }, [customers, setSelectedCustomer]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim() === "") {
      setSearchData([]);
      setIsSearching(false);
    } else {
      const filterCustomers = customers && customers.filter((customer) =>
        customer.name.toLowerCase().includes(term.toLowerCase())
      );
      setSearchData(filterCustomers);
      setIsSearching(true);
    }
  };

  const handleCustomerClick = (customer) => {
    setSearchTerm(customer.name);
    setSearchData([]);
    setSelectedCustomer(customer); // Update selected customer
    setIsSearching(false);
  };

  return (
    <div className="col-md-6">
      <h5 className='d-none d-md-block'>Search Customer</h5>
      <div className="position-relative mt-2 mt-md-0">
        <input
          type="text"
          placeholder="Search Customer..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control"
          style={{ paddingRight: "2.5rem" }}
        />
        <AiOutlineSearch
          size={25}
          className="position-absolute"
          style={{ right: "0.5rem", top: "0.35rem", cursor: "pointer" }}
        />
        {isSearching && searchData.length > 0 && (
          <div
            className="position-absolute bg-light shadow-sm p-3"
            style={{ minHeight: "30vh", zIndex: 9, width: "100%" }}
          >
            {searchData.map((customer, index) => (
              <div
                key={index}
                className="d-flex align-items-center mb-2"
                style={{ cursor: "pointer" }}
                onClick={() => handleCustomerClick(customer)}
              >
                <h1 className="h6 mb-0">{customer.name}</h1>
              </div>
            ))}
          </div>
        )}
        {isSearching && searchData.length === 0 && (
          <div
            className="position-absolute bg-light shadow-sm p-3"
            style={{ minHeight: "30vh", zIndex: 9, width: "100%" }}
          >
            <p className="text-center text-danger mb-0 fs-3">Customer not found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSearch;