import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  invoices: [],
  invoice: null,
  success: false,
  error: null,
  message: null,
};

export const billingReducer = createReducer(initialState, (builder) => {
  builder
    // Create Invoice
    .addCase('createInvoiceRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createInvoiceSuccess', (state, action) => {
      state.isLoading = false;
      state.invoice = action.payload;
      state.success = true;
      state.message = "Invoice created successfully!";
    })
    .addCase('createInvoiceFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Invoices
    .addCase('getAllInvoicesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllInvoicesSuccess', (state, action) => {
      state.isLoading = false;
      state.invoices = action.payload;
    })
    .addCase('getAllInvoicesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Get Invoice Details
    .addCase('getInvoiceDetailsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getInvoiceDetailsSuccess', (state, action) => {
      state.isLoading = false;
      state.invoice = action.payload;
    })
    .addCase('getInvoiceDetailsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Update Invoice
    .addCase('updateInvoiceRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updateInvoiceSuccess', (state, action) => {
      state.isLoading = false;
      state.invoice = action.payload;
      state.success = true;
      state.message = "Invoice updated successfully!";
    })
    .addCase('updateInvoiceFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Invoice
    .addCase('deleteInvoiceRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteInvoiceSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteInvoiceFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
