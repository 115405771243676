import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import PaginationComponent from '../../components/pagination/PaginationComponent';

import CreateProductModal from './CreateProductModal.jsx';
import EditProductModal from './EditProductModal.jsx';
import { deleteProduct, getAllProducts } from '../../../redux/actions/productAction.js';

const ProductsPage = () => {
  const dispatch = useDispatch();
  const { products, error, message, isLoading } = useSelector((state) => state.product);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingProduct, setEditingProduct] = useState(null);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const productsPerPage = 13;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(products.length / productsPerPage);

  // Calculate the products to display based on current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentProducts = products.slice(startIndex, startIndex + productsPerPage);

  useEffect(() => {
    dispatch(getAllProducts());

    if (error) {
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [dispatch, error, message]);

  const handleDelete = (productId) => {
    setProductIdToDelete(productId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteProduct(productIdToDelete));
    setShowConfirmDeleteModal(false);
    setProductIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setProductIdToDelete(null);
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingProduct(null);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col className="d-flex align-items-center">
          <h2 className="mb-0">Products</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Create Product
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="table-container mt-2">
            <Table striped bordered hover className='table-sm'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Cost Price</th>
                  <th>Selling Price</th>
                  <th>Category</th>
                  <th>Supplier</th>
                  <th>Unit</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentProducts.length > 0 ? (
                  currentProducts.map((product, index) => (
                    <tr key={product?._id}>
                    <td>{startIndex + index + 1}</td>
                    <td>{product?.name || 'N/A'}</td>
                    <td>{product?.costPrice?.toFixed(2) || '0.00'}</td>
                    <td>{product?.sellingPrice?.toFixed(2) || '0.00'}</td>
                    <td>{product?.category?.name || 'N/A'}</td>
                    <td>{product?.supplier?.name || 'N/A'}</td>
                    <td>{product?.unit?.abbreviation || 'N/A'}</td>
                    <td>
                      <Button variant="info" onClick={() => handleEdit(product)}>
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        className="ms-2"
                        onClick={() => handleDelete(product?._id)}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                  
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">No products found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {/* Only show pagination if the total number of products exceeds productsPerPage */}
          {totalPages > 1 && (
            <div className="d-flex justify-content-center mt-2">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          )}
        </Col>
      </Row>

      {showCreateModal && (
        <CreateProductModal
          show={showCreateModal}
          onHide={handleCreateModalClose}
        />
      )}
      {showModal && editingProduct && (
        <EditProductModal
          show={showModal}
          onHide={handleModalClose}
          product={editingProduct}
        />
      )}
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default ProductsPage;
