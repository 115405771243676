import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  customer: null,
  customers: [],
  success: false,
  error: null,
  message: null,
};

export const customerReducer = createReducer(initialState, (builder) => {
  builder
    // Customer Creation
    .addCase('customerCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('customerCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.customer = action.payload;
      state.success = true;
      state.message = "Customer created successfully!";
    })
    .addCase('customerCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    .addCase('getAllCustomersRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllCustomersSuccess', (state, action) => {
      state.isLoading = false;
      state.customers = action.payload;
    })
    .addCase('getAllCustomersFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Delete Customer
    .addCase('deleteCustomerRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteCustomerSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteCustomerFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Customer
    .addCase('editCustomerRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editCustomerSuccess', (state, action) => {
      state.isLoading = false;
      state.customer = action.payload;
      state.success = true;
      state.message = "Customer updated successfully!";
    })
    .addCase('editCustomerFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});