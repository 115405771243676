import PropTypes from 'prop-types';
import { useState } from 'react';
import { HiOutlineMinus, HiPlus } from 'react-icons/hi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {

  const [value, setValue] = useState(data.qty);

  const updateCart = (newQty) => {
    setValue(newQty);
    const updateCartData = { ...data, qty: newQty };
    quantityChangeHandler(updateCartData);
  };

  const increment = () => {
    if (data.quantity <= value) {
      toast.error("Product stock limited!");
    } else {
      updateCart(value + 1);
    }
  };

  const decrement = () => {
    updateCart(value === 1 ? 1 : value - 1);
  };

  // Handle undefined or missing sellingPrice
  const sellingPrice = data.sellingPrice ? data.sellingPrice : 0;

  return (
    <tr>
      <td>{data.name}</td>
      <td className="text-center">
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-sm btn-outline-secondary mx-1"
            onClick={decrement}
            disabled={value === 1}
          >
            <HiOutlineMinus size={15} />
          </button>
          <span className="mx-2">{value}</span>
          <button
            className="btn btn-sm btn-outline-secondary mx-1"
            onClick={increment}
          >
            <HiPlus size={15} />
          </button>
        </div>
      </td>
      <td className="text-right">{sellingPrice.toFixed(2)}</td>
      <td className="text-right">{(value * sellingPrice).toFixed(2)}</td>
      <td className="text-center">
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => removeFromCartHandler(data)}
        >
          <RiDeleteBinLine />
        </button>
      </td>
    </tr>
  );
};

CartSingle.propTypes = {
  data: PropTypes.object.isRequired,
  quantityChangeHandler: PropTypes.func.isRequired,
  removeFromCartHandler: PropTypes.func.isRequired,
};

export default CartSingle;