import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  expense: null,
  expenses: [],
  success: false,
  error: null,
  message: null,
};

export const expenseReducer = createReducer(initialState, (builder) => {
  builder
    // Expense Creation
    .addCase('expenseCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('expenseCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.expense = action.payload;
      state.success = true;
      state.message = "Expense created successfully!";
    })
    .addCase('expenseCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Expenses
    .addCase('getAllExpensesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllExpensesSuccess', (state, action) => {
      state.isLoading = false;
      state.expenses = action.payload;
    })
    .addCase('getAllExpensesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Expense
    .addCase('editExpenseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editExpenseSuccess', (state, action) => {
      state.isLoading = false;
      state.expense = action.payload;
      state.success = true;
      state.message = "Expense updated successfully!";
    })
    .addCase('editExpenseFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Expense
    .addCase('deleteExpenseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteExpenseSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteExpenseFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});