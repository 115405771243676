import Lottie from "lottie-react";
import animationData from "../../assets/animations/loader1.json";

const Loader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <Lottie
        animationData={animationData}
        loop={false}
        autoplay={true}
        style={{ width: 300, height: 300 }}
      />
    </div>
  );
};

export default Loader;
