/* eslint-disable react/prop-types */
import { Modal, Button, Form } from 'react-bootstrap';

const SaleDetailsModal = ({ show, handleClose, sale }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Sale Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formSaleDate">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" defaultValue={new Date(sale.saleDate).toISOString().split('T')[0]} />
          </Form.Group>
          <Form.Group controlId="formCustomer">
            <Form.Label>Customer</Form.Label>
            <Form.Control type="text" defaultValue={sale.customer.name} />
          </Form.Group>
          <Form.Group controlId="formTotalAmount">
            <Form.Label>Total Amount</Form.Label>
            <Form.Control type="number" defaultValue={sale.totalAmount} />
          </Form.Group>
          <Form.Group controlId="formPaymentMethod">
            <Form.Label>Payment Method</Form.Label>
            <Form.Control type="text" defaultValue={sale.paymentMethod} />
          </Form.Group>
          <Form.Group controlId="formCashier">
            <Form.Label>Cashier</Form.Label>
            <Form.Control type="text" defaultValue={sale.cashier.firstName} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => { /* Save the updated details */ }}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaleDetailsModal;
