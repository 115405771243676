import axios from "axios";
import { server } from "../../server/server";

// Create Supplier
export const createSupplier = (newForm) => async (dispatch) => {
  try {
    dispatch({ type: "supplierCreateRequest" });

    // const config = { headers: { "Content-Type": "multipart/form-data" } }

    const { data } = await axios.post(`${server}/supplier/create-supplier`, newForm);

    dispatch({
      type: "supplierCreateSuccess",
      payload: data.supplier,
    });
  } catch (error) {
    dispatch({
      type: "supplierCreateFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Suppliers
export const getAllSuppliers = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllSuppliersRequest" });

    const { data } = await axios.get(`${server}/supplier/get-all-suppliers`);

    dispatch({
      type: "getAllSuppliersSuccess",
      payload: data.suppliers,
    });
  } catch (error) {
    dispatch({
      type: "getAllSuppliersFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Supplier
export const editSupplier = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editSupplierRequest" });

    const { data } = await axios.put(`${server}/supplier/update-supplier/${id}`, updatedData);

    dispatch({
      type: "editSupplierSuccess",
      payload: data.supplier,
    });

    // Optionally, fetch all suppliers again to update the list
    dispatch(getAllSuppliers());
  } catch (error) {
    dispatch({
      type: "editSupplierFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Supplier
export const deleteSupplier = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteSupplierRequest" });

    const { data } = await axios.delete(`${server}/supplier/delete-supplier/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteSupplierSuccess",
      payload: data.message,
    });

    // Optionally, fetch all suppliers again to update the list
    dispatch(getAllSuppliers());
  } catch (error) {
    dispatch({
      type: "deleteSupplierFailed",
      payload: error.response.data.message,
    });
  }
};
