import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { server } from "../../../server/server";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim whitespace from email and password
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    try {
      await axios.post(`${server}/user/login`, { email: trimmedEmail, password: trimmedPassword }, {
        withCredentials: true
      });

      toast.success("Login successful");
      navigate("/");
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        navigate('/verify-account');
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
        console.log(error.response.data.message);
      }
    }
  };

  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <div className="container mt-2">
        <div className="row justify-content-center mx-0">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <div className="card">
              <div className="card-header text-center">Admin | Login</div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      autoComplete="email"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password:
                    </label>
                    <div className="input-group">
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        className="form-control"
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span className="input-group-text">
                        {passwordVisible ? (
                          <FaEye onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                        ) : (
                          <FaEyeSlash onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mb-3 d-flex justify-content-between">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberMe"
                      />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember Me
                      </label>
                    </div>
                    <Link to="/admin/forgot-password" className="text-primary">
                      Forgot Password
                    </Link>
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;