import { useState } from 'react';
import Cart from '../../components/cart-list/Cart.jsx';
import CustomerSearch from "../../components/customer-search/CustomerSearch.jsx";
import ProductItems from '../../components/product-items/ProductItems.jsx';
import ProductSearch from "../../components/product-search/ProductSearch.jsx";


const PosDashboard = () => {
  // State to manage selected customer
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  return (
    <div className="container-fluid">
      <div className="fixed row mb-2 mt-2">
        {/* search product */}
        <ProductSearch />

        {/* search customer */}
        <CustomerSearch setSelectedCustomer={setSelectedCustomer} />
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-7 d-none d-md-block">
          <h2 className="fs-5">Product Items</h2>
          <ProductItems />
        </div>

        <div className="col-md-6 col-lg-5">
          <h2 className="text-center fs-5">Order List</h2>
          <Cart selectedCustomer={selectedCustomer} />
        </div>
      </div>
    </div>
  );
};

export default PosDashboard;
