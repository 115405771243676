/* eslint-disable react/no-unescaped-entities */

import { Container, Row, Col, Button } from 'react-bootstrap';

const PageNotFound = () => {
  return (
    <Container className="text-center mt-5">
      <Row>
        <Col>
          <h1 className="display-1">404</h1>
          <h2>Page Not Found</h2>
          <p>The page you are looking for doesn't exist or has been moved.</p>
          <Button variant="primary" href="/">Go to Home</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default PageNotFound;
