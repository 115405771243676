import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  productUnit: null,
  productUnits: [],
  success: false,
  error: null,
  message: null,
};

export const productUnitReducer = createReducer(initialState, (builder) => {
  builder
    // Create Product Unit
    .addCase('productUnitCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('productUnitCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.productUnit = action.payload;
      state.success = true;
      state.message = 'Product Unit created successfully!';
    })
    .addCase('productUnitCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Product Units
    .addCase('getAllProductUnitsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllProductUnitsSuccess', (state, action) => {
      state.isLoading = false;
      state.productUnits = action.payload;
    })
    .addCase('getAllProductUnitsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Product Unit
    .addCase('editProductUnitRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editProductUnitSuccess', (state, action) => {
      state.isLoading = false;
      state.productUnit = action.payload;
      state.success = true;
      state.message = 'Product Unit updated successfully!';
    })
    .addCase('editProductUnitFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Product Unit
    .addCase('deleteProductUnitRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteProductUnitSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteProductUnitFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
