import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import CreateRoleModal from './CreateRoleModal.jsx';
import EditRoleModal from './EditRoleModal.jsx';
import { deleteRole, getAllRoles } from '../../../redux/actions/roleAction';

const RolesPage = () => {
  const dispatch = useDispatch();
  const { roles, error, message, isLoading } = useSelector((state) => state.role);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingRole, setEditingRole] = useState(null);
  const [roleIdToDelete, setRoleIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllRoles());
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [error, message, dispatch]);

  const handleDelete = (roleId) => {
    setRoleIdToDelete(roleId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteRole(roleIdToDelete));
    setShowConfirmDeleteModal(false);
    setRoleIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setRoleIdToDelete(null);
  };

  const handleEdit = (role) => {
    setEditingRole(role);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingRole(null);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col className="d-flex align-items-center">
          <h2>Roles</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Create Role
          </Button>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Role Name</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {roles && roles.map((role) => (
                  <tr key={role._id}>
                    <td>{role.name}</td>
                    <td>{role.description}</td>
                    <td>
                      <Button variant="info" onClick={() => handleEdit(role)}>Edit</Button>
                      <Button variant="danger" className='ms-2' onClick={() => handleDelete(role._id)} disabled={isLoading}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showCreateModal && <CreateRoleModal show={showCreateModal} onHide={handleCreateModalClose} />}
            {showModal && editingRole && <EditRoleModal show={showModal} onHide={handleModalClose} role={editingRole} />}
            <ConfirmDeleteModal show={showConfirmDeleteModal} onClose={handleCloseConfirmDeleteModal} onConfirm={handleConfirmDelete} />


          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RolesPage;