import { useNavigate } from 'react-router-dom';
import Login from './Login.jsx';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const LoginPage = () => {

  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Login />
    </>
  );
};

export default LoginPage;