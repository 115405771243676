import axios from "axios";
import { server } from "../../server/server";

// Create Invoice
export const createInvoice = (invoiceData) => async (dispatch) => {
  try {
    dispatch({ type: "createInvoiceRequest" });

    const { data } = await axios.post(`${server}/billing/create-invoice`, invoiceData);

    dispatch({
      type: "createInvoiceSuccess",
      payload: data.invoice,
    });
  } catch (error) {
    dispatch({
      type: "createInvoiceFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Invoices
export const getAllInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllInvoicesRequest" });

    const { data } = await axios.get(`${server}/billing/get-all-invoices`);

    dispatch({
      type: "getAllInvoicesSuccess",
      payload: data.invoices,
    });
  } catch (error) {
    dispatch({
      type: "getAllInvoicesFailed",
      payload: error.response.data.message,
    });
  }
};

// Get Invoice Details
export const getInvoiceDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getInvoiceDetailsRequest" });

    const { data } = await axios.get(`${server}/billing/get-invoice-details/${id}`);

    dispatch({
      type: "getInvoiceDetailsSuccess",
      payload: data.invoice,
    });
  } catch (error) {
    dispatch({
      type: "getInvoiceDetailsFailed",
      payload: error.response.data.message,
    });
  }
};

// Update Invoice
export const updateInvoice = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "updateInvoiceRequest" });

    const { data } = await axios.put(`${server}/billing/update-invoice/${id}`, updatedData);

    dispatch({
      type: "updateInvoiceSuccess",
      payload: data.invoice,
    });
  } catch (error) {
    dispatch({
      type: "updateInvoiceFail",
      payload: error.response.data.message,
    });
  }
};

// Delete Invoice
export const deleteInvoice = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteInvoiceRequest" });

    const { data } = await axios.delete(`${server}/billing/delete-invoice/${id}`);

    dispatch({
      type: "deleteInvoiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteInvoiceFail",
      payload: error.response.data.message,
    });
  }
};
