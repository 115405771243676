const AdminFooter = () => {
  const year = new Date().getFullYear();

  return (
    <div className="admin-footer text-white bg-dark text-center py-3">
      <p className="mb-0 fs-6">
        © 2016-{year} Matrix | Pro All rights reserved.
      </p>
    </div>
  );
}

export default AdminFooter;
