/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { updateInvoice } from '../../../redux/actions/billingAction.js';

const EditBillingModal = ({ show, onHide, billing }) => {
  const dispatch = useDispatch();
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [subtotal, setSubtotal] = useState('');
  const [tax, setTax] = useState('');
  const [total, setTotal] = useState('');

  useEffect(() => {
    if (billing) {
      setInvoiceNumber(billing.invoiceNumber);
      setCustomerName(billing.customerName);
      setSubtotal(billing.subtotal);
      setTax(billing.tax);
      setTotal(billing.total);
    }
  }, [billing]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateInvoice(billing._id, { invoiceNumber, customerName, subtotal, tax, total }));
    onHide(); // Close modal after submission
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Billing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Invoice Number</Form.Label>
            <Form.Control
              type="text"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Subtotal</Form.Label>
            <Form.Control
              type="number"
              value={subtotal}
              onChange={(e) => setSubtotal(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tax</Form.Label>
            <Form.Control
              type="number"
              value={tax}
              onChange={(e) => setTax(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total</Form.Label>
            <Form.Control
              type="number"
              value={total}
              onChange={(e) => setTotal(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditBillingModal;
