import PropTypes from 'prop-types';

const CashPaymentForm = ({ cashReceived, handleCashReceivedChange, error }) => (
  <div className="mt-3">
    <div className="mb-3">
      <label htmlFor="cashReceived" className="form-label">Cash Received</label>
      <input
        type="text"
        className="form-control"
        id="cashReceived"
        value={cashReceived}
        onChange={handleCashReceivedChange}
        placeholder="Enter cash received"
        inputMode="numeric"
        pattern="[0-9]*"
        required
      />
      {error && <div className="text-danger mt-1">{error}</div>}
    </div>
  </div>
);

CashPaymentForm.propTypes = {
  cashReceived: PropTypes.string.isRequired,
  handleCashReceivedChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default CashPaymentForm;
