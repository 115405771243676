import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import CreateOrderModal from './CreateOrderModal.jsx';
import EditOrderModal from './EditOrderModal.jsx';
import { deleteOrder, getAllOrders } from '../../../redux/actions/orderAction.js';

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { orders, error, message, isLoading } = useSelector((state) => state.order);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingOrder, setEditingOrder] = useState(null);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllOrders());
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearErrors' });
    }


    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [error, message, dispatch]);

  const handleDelete = (orderId) => {
    setOrderIdToDelete(orderId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteOrder(orderIdToDelete));
    setShowConfirmDeleteModal(false);
    setOrderIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setOrderIdToDelete(null);
  };

  const handleEdit = (order) => {
    setEditingOrder(order);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingOrder(null);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col className="d-flex align-items-center">
          <h2>Orders</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Create Order
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="table-container mt-2">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Customer Name</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order.orderNumber}</td>
                    <td>{order.customer.name}</td>
                    <td>{order.totalAmount}</td>
                    <td>{order.status}</td>
                    <td>
                      <Button variant="info" onClick={() => handleEdit(order)}>Edit</Button>
                      <Button variant="danger" className='ms-2' onClick={() => handleDelete(order._id)} disabled={isLoading}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showCreateModal && <CreateOrderModal show={showCreateModal} onHide={handleCreateModalClose} />}
            {showModal && editingOrder && <EditOrderModal show={showModal} onHide={handleModalClose} order={editingOrder} />}
            <ConfirmDeleteModal show={showConfirmDeleteModal} onClose={handleCloseConfirmDeleteModal} onConfirm={handleConfirmDelete} />

          </div>
        </Col>

      </Row>
    </div>
  );
};

export default OrdersPage;