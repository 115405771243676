import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  products: [],
  product: null,
  error: null,
  message: null,
  success: false,
};

export const productReducer = createReducer(initialState, (builder) => {
  builder
    // Product Creation
    .addCase('createProductRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createProductSuccess', (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
      state.success = true;
      state.message = "Product created successfully!";
    })
    .addCase('createProductFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Products
    .addCase('getAllProductsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllProductsSuccess', (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    })
    .addCase('getAllProductsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Products by Store
    .addCase('getProductsByStoreRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getProductsByStoreSuccess', (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    })
    .addCase('getProductsByStoreFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Product
    .addCase('editProductRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editProductSuccess', (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
      state.success = true;
      state.message = "Product updated successfully!";
    })
    .addCase('editProductFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Product
    .addCase('deleteProductRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteProductSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteProductFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Get Product by ID
    .addCase('getProductByIdRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getProductByIdSuccess', (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
    })
    .addCase('getProductByIdFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
