import PropTypes from 'prop-types';

const MpesaPaymentForm = ({ mpesaDetails, handleMpesaDetailsChange }) => (
  <div className="mt-3">
    <div className="mb-3">
      <label htmlFor="mpesaDetails" className="form-label">MPesa Transaction Reference</label>
      <input
        type="text"
        className="form-control"
        id="mpesaDetails"
        value={mpesaDetails}
        onChange={handleMpesaDetailsChange}
        placeholder="Enter MPesa transaction reference"
      />
    </div>
  </div>
);

MpesaPaymentForm.propTypes = {
  mpesaDetails: PropTypes.string.isRequired,
  handleMpesaDetailsChange: PropTypes.func.isRequired
};

export default MpesaPaymentForm;
