import axios from "axios";
import { server } from "../../server/server";

// Create Stock
export const createStock = (newStock) => async (dispatch) => {
  try {
    dispatch({
      type: "stockCreateRequest",
    });

    const { data } = await axios.post(`${server}/stock/create-stock`, newStock, { withCredentials: true });
    dispatch({
      type: "stockCreateSuccess",
      payload: data.stock,
    });
  } catch (error) {
    dispatch({
      type: "stockCreateFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Stocks
export const getAllStocks = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllStocksRequest" });

    const { data } = await axios.get(`${server}/stock/get-all-stocks`, { withCredentials: true });

    dispatch({
      type: "getAllStocksSuccess",
      payload: data.stocks,
    });
  } catch (error) {
    dispatch({
      type: "getAllStocksFailed",
      payload: error.response.data.message,
    });
  }
};

export const getStockByStore = (storeId) => async (dispatch) => {
  try {
    dispatch({ type: "getStockByStoreRequest" });
    const { data } = await axios.get(`${server}/stock/store/${storeId}`, { withCredentials: true });
    dispatch({
      type: "getStockByStoreSuccess",
      payload: data.stocks,
    });
  } catch (error) {
    dispatch({
      type: "getStockByStoreFailed",
      payload: error.response.data.message,
    });
  }
};

// Transfer Stock
export const transferStock = (productId, transferData) => async (dispatch) => {
  try {
    dispatch({ type: "transferStockRequest" });

    const { data } = await axios.post(`${server}/stock/transfer-stock/${productId}`,
      transferData, { withCredentials: true });

    dispatch({
      type: "transferStockSuccess",
      payload: data.message,
    });

    // Optionally, fetch products by store or all products to update the list
    // dispatch(getProductsByStore(storeId));
    // or
    // dispatch(getAllProducts());

  } catch (error) {
    dispatch({
      type: "transferStockFailed",
      payload: error.response.data.message,
    });
  }
};

// Add Stock
export const addStock = (productId, quantity) => async (dispatch) => {
  try {
    dispatch({ type: "addStockRequest" });

    const { data } = await axios.put(`${server}/stock/add-stock/${productId}`, { quantity }, { withCredentials: true });

    dispatch({
      type: "addStockSuccess",
      payload: data.message,
    });

    // Optionally, fetch products by store or all products to update the list
    // dispatch(getProductsByStore(storeId));
    // or
    // dispatch(getAllProducts());

  } catch (error) {
    dispatch({
      type: "addStockFailed",
      payload: error.response.data.message,
    });
  }
};


// Edit Stock
export const editStock = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editStockRequest" });

    const { data } = await axios.put(`${server}/stock/update-stock/${id}`, updatedData, { withCredentials: true });

    dispatch({
      type: "editStockSuccess",
      payload: data.stock,
    });

    // Optionally, fetch all stocks again to update the list
    dispatch(getAllStocks());
  } catch (error) {
    dispatch({
      type: "editStockFailed",
      payload: error.response.data.message,
    });
  }
};


// Delete Stock
export const deleteStock = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteStockRequest" });

    const { data } = await axios.delete(`${server}/stock/delete-stock/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteStockSuccess",
      payload: data.message,
    });

    // Optionally, fetch all stocks again to update the list
    dispatch(getAllStocks());
  } catch (error) {
    dispatch({
      type: "deleteStockFailed",
      payload: error.response.data.message,
    });
  }
};
