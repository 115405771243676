/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllCategories } from '../../../redux/actions/categoryAction';
import { createProduct, getAllProducts } from '../../../redux/actions/productAction';
import { getAllProductUnits } from '../../../redux/actions/productUnitAction';
import { getAllSuppliers } from '../../../redux/actions/supplierAction';
import { getAllProductVariants } from '../../../redux/actions/productVariantAction';

const CreateProductModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.category);
  const { suppliers } = useSelector((state) => state.supplier);
  const { productUnits } = useSelector((state) => state.productUnit);
  const { productVariants } = useSelector((state) => state.productVariant);

  const [name, setName] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [category, setCategory] = useState('');
  const [supplier, setSupplier] = useState('');
  const [unit, setUnit] = useState('');
  const [color, setColor] = useState('');
  const [availableIn, setAvailableIn] = useState('');
  const [dimension, setDimension] = useState('');
  const [taxable, setTaxable] = useState(false); // New taxable state

  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSuppliers());
      dispatch(getAllProductUnits());
      dispatch(getAllProductVariants());
    }
  }, [show, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !category || !supplier || !unit || !availableIn || !dimension) {
      toast.error('Please fill out all required fields.');
      return;
    }

    const productData = {
      name,
      costPrice,
      sellingPrice,
      category,
      supplier,
      unit,
      color,
      availableIn,
      dimension,
      taxable // Include taxable in product data
    };

    dispatch(createProduct(productData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Product created successfully!');
      dispatch(getAllProducts());
      setName('');
      setCostPrice('');
      setSellingPrice('');
      setCategory('');
      setSupplier('');
      setUnit('');
      setColor('');
      setAvailableIn('');
      setDimension('');
      setTaxable(false); // Reset taxable state
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const sizeVariant = productVariants ? productVariants.find(variant => variant.variantType === 'Size') : null;
  const colorVariant = productVariants ? productVariants.find(variant => variant.variantType === 'Color') : null;
  const weightVariant = productVariants ? productVariants.find(variant => variant.variantType === 'Weight') : null;

  const sizeOptions = sizeVariant ? sizeVariant.variations : [];
  const colorOptions = colorVariant ? colorVariant.variations : [];
  const weightOptions = weightVariant ? weightVariant.variations : [];

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  <option value="">Select a category</option>
                  {categories && categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Cost Price</Form.Label>
                <Form.Control
                  type="number"
                  value={costPrice}
                  onChange={(e) => setCostPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Selling Price</Form.Label>
                <Form.Control
                  type="number"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Selling Unit</Form.Label>
                <Form.Control
                  as="select"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  required
                >
                  <option value="">Select selling unit</option>
                  {productUnits && productUnits.length > 0 ? (
                    productUnits.map((unit) => (
                      <option key={unit._id} value={unit._id}>
                        {unit.unitName}
                      </option>
                    ))
                  ) : (
                    <option disabled>No units available</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Supplier</Form.Label>
                <Form.Control
                  as="select"
                  value={supplier}
                  onChange={(e) => setSupplier(e.target.value)}
                  required
                >
                  <option value="">Select a supplier</option>
                  {suppliers && suppliers.length > 0 ? (
                    suppliers.map((sup) => (
                      <option key={sup._id} value={sup._id}>
                        {sup.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No suppliers available</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  as="select"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  <option value="">Select color</option>
                  {colorOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2">
                <Form.Label>Available In</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Size"
                    name="availableIn"
                    type="radio"
                    id="availableInSize"
                    checked={availableIn === 'Size'}
                    onChange={() => setAvailableIn('Size')}
                  />
                  <Form.Check
                    inline
                    label="Weight"
                    name="availableIn"
                    type="radio"
                    id="availableInWeight"
                    checked={availableIn === 'Weight'}
                    onChange={() => setAvailableIn('Weight')}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-2">
                <Form.Label>{availableIn === 'Size' ? 'Size' : 'Weight'}</Form.Label>
                <Form.Control
                  as="select"
                  value={dimension}
                  onChange={(e) => setDimension(e.target.value)}
                  required
                >
                  <option value="">Select {availableIn === 'Size' ? 'Size' : 'Weight'}</option>
                  {(availableIn === 'Size' ? sizeOptions : weightOptions).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Taxable"
                  checked={taxable}
                  onChange={(e) => setTaxable(e.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <Button variant="secondary" onClick={onHide}>Close</Button>
            <Button
              className="ms-3"
              type="submit"
              variant="primary"
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'Create Product'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProductModal;
