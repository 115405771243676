import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  order: null,
  orders: [],
  success: false,
  error: null,
  message: null,
};

export const orderReducer = createReducer(initialState, (builder) => {
  builder
    // Order Creation
    .addCase('createOrderRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createOrderSuccess', (state, action) => {
      state.isLoading = false;
      state.order = action.payload;
      state.success = true;
      state.message = "Order created successfully!";
    })
    .addCase('createOrderFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Orders
    .addCase('getAllOrdersRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllOrdersSuccess', (state, action) => {
      state.isLoading = false;
      state.orders = action.payload;
    })
    .addCase('getAllOrdersFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Order
    .addCase('editOrderRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editOrderSuccess', (state, action) => {
      state.isLoading = false;
      state.order = action.payload;
      state.success = true;
      state.message = "Order updated successfully!";
    })
    .addCase('editOrderFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Order
    .addCase('deleteOrderRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteOrderSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteOrderFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
