import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaArrowCircleRight, FaChartBar, FaChartLine, FaCreditCard, FaDollarSign, FaUsersCog } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllExpenses } from '../../../redux/actions/expenseAction.js';
import { getSalesByStore, getDailySales, getWeeklySales, getMonthlySales } from '../../../redux/actions/saleAction.js';
import SalesExpensesChart from './SalesExpensesChart.jsx';
// import TopSellingProductsChart from './TopSellingProductsChart.jsx';

const AdminDashboard = () => {
  const { users, user } = useSelector((state) => state.user);
  const { sales, dailySales, weeklySales, monthlySales } = useSelector((state) => state.sale);
  const { expenses } = useSelector((state) => state.expense);
  const dispatch = useDispatch();


  const storeId = user && user.store._id;

  const [totalSales, setTotalSales] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [dailyTotal, setDailyTotal] = useState(0);
  const [weeklyTotal, setWeeklyTotal] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);

  useEffect(() => {
    dispatch(getSalesByStore(storeId));
    dispatch(getAllExpenses());
    dispatch(getDailySales());
    dispatch(getWeeklySales());
    dispatch(getMonthlySales());
  }, [dispatch, storeId]);

  useEffect(() => {
    const totalAmount = sales && sales.reduce((acc, sale) => acc + sale.totalAmount, 0);
    setTotalSales(totalAmount);

    const totalExpenses = expenses && expenses.reduce((acc, expense) => acc + expense.amount, 0);
    setTotalExpenses(totalExpenses);

    const netTotal = totalAmount - totalExpenses;
    setNetAmount(netTotal);

    const dailyTotal = dailySales && dailySales.reduce((acc, sale) => acc + sale.totalAmount, 0);
    setDailyTotal(dailyTotal);

    const weeklyTotal = weeklySales && weeklySales.reduce((acc, sale) => acc + sale.totalAmount, 0);
    setWeeklyTotal(weeklyTotal);

    const monthlyTotal = monthlySales && monthlySales.reduce((acc, sale) => acc + sale.totalAmount, 0);
    setMonthlyTotal(monthlyTotal);
  }, [sales, expenses, dailySales, weeklySales, monthlySales]);

  return (
    <div className="container-fluid mb-lg-0">
      <div className="row">
        <div className="col-sm-6">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      {/* Cards */}
      <Row className="gy-2">
  <Col lg={4} sm={6} xs={12}>
    <Card className="bg-success text-white custom-panel">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>{users && users.length}</h2>
            <p>Users</p>
          </div>
          <FaUsersCog className="icon" />
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/users" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col>
  <Col lg={4} sm={6} xs={12}>
    <Card className="bg-success text-white custom-panel">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Ksh.{totalSales.toFixed(2)}</h2>
            <p>Total Sales</p>
          </div>
          <FaDollarSign className="icon" />
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/sales" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col>
  <Col lg={4} sm={6} xs={12}>
    <Card className="bg-info text-white custom-panel">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Ksh.{totalExpenses.toFixed(2)}</h2>
            <p>Total Expenses</p>
          </div>
          <FaCreditCard className="icon" />
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/expenses" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col>
  <Col lg={4} sm={6} xs={12}>
    <Card className={`custom-panel ${netAmount < 0 ? 'bg-danger' : 'bg-primary'} text-white`}>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Ksh.{netAmount.toFixed(2)}</h2>
            <p>{netAmount < 0 ? 'Net Loss' : 'Net Profit'}</p>
          </div>
          {netAmount < 0 ? <FaChartBar className="icon" /> : <FaChartLine className="icon" />}
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/financials" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col>
  {/* New Cards for Daily, Weekly, and Monthly Sales */}
  <Col lg={4} sm={6} xs={12}>
    <Card className="bg-primary text-white custom-panel">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Ksh.{dailyTotal.toFixed(2)}</h2>
            <p>Daily Sales</p>
          </div>
          <FaChartLine className="icon" />
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/sales/daily" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col>
  <Col lg={4} sm={6} xs={12}>
    <Card className="bg-secondary text-white custom-panel">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Ksh.{weeklyTotal.toFixed(2)}</h2>
            <p>Weekly Sales</p>
          </div>
          <FaChartBar className="icon" />
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/sales/weekly" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col>
  {/* <Col lg={4} sm={6} xs={12}>
    <Card className="bg-dark text-white custom-panel">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Ksh.{monthlyTotal.toFixed(2)}</h2>
            <p>Monthly Sales</p>
          </div>
          <FaChartLine className="icon" />
        </div>
      </Card.Body>
      <Card.Footer>
        <Link to="/sales/monthly" className="text-white custom-text">
          More info <FaArrowCircleRight />
        </Link>
      </Card.Footer>
    </Card>
  </Col> */}
</Row>


      {/* Add the Chart */}
      <Row className="gy-2 mt-4">
        <Col lg={6}>
          <Card className="bg-light">
            <Card.Body>
              <SalesExpensesChart
                sales={totalSales}
                expenses={totalExpenses}
                dailySales={dailySales}
                weeklySales={weeklySales}
                monthlySales={monthlySales}
              />
            </Card.Body>
          </Card>
        </Col>
        {/* <Col lg={6}>
          <Card className="bg-light">
            <Card.Body>
              <TopSellingProductsChart products={exampleTopSellingProducts} />
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default AdminDashboard;
