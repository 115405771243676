import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal.jsx';
import CreateCategoryModal from './CreateCategoryModal.jsx';
import EditCategoryModal from './EditCategoryModal.jsx';
import { deleteCategory, getAllCategories } from '../../../redux/actions/categoryAction.js';

const CategoryPage = () => {
  const { categories, error, message, isLoading } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllCategories());
    if (error) {
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [error, message, dispatch]);

  const handleDelete = (categoryId) => {
    setCategoryIdToDelete(categoryId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteCategory(categoryIdToDelete));
    setShowConfirmDeleteModal(false);
    setCategoryIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setCategoryIdToDelete(null);
  };

  const handleEdit = (category) => {
    setEditingCategory(category);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditingCategory(null);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col className="d-flex align-items-center">
          <h2 className='mb-0'>Categories</h2>
          <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
            Add Category
          </Button>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <div className="table-container">
            <Table striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {categories && categories.map((category, index) => (
                  <tr key={category._id}>
                    <td>{index + 1}</td>
                    <td>{category.name}</td>
                    <td>{category.description}</td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => handleEdit(category)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        className='ms-2'
                        onClick={() => handleDelete(category._id)}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {showCreateModal && (
            <CreateCategoryModal
              show={showCreateModal}
              onHide={handleCreateModalClose}
            />
          )}
          {showEditModal && editingCategory && (
            <EditCategoryModal
              show={showEditModal}
              onHide={handleEditModalClose}
              category={editingCategory}
            />
          )}
          <ConfirmDeleteModal
            show={showConfirmDeleteModal}
            onClose={handleCloseConfirmDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Col>

      </Row>
    </div>
  );
};

export default CategoryPage;
